import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';  
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash';


// The argument names of the component is not the same as just ordered arguments of a function.
// These are named components, so they are tightly connected to the place where component is rendered.
// These pairs of names should match.
function InputButtonSet({ tgNews, onSend }) {  



const [inputValues, setInputValues] = useState(() => {
let myDict = {};
  return tgNews.reduce((acc, {url, channels}, index) => {
	  
	  

if (myDict.hasOwnProperty(channels[0])) {
  myDict[channels[0]] += 1;
} else {
  myDict[channels[0]] = 0;
}
		  
if (!(acc.hasOwnProperty(channels[0]))) {
  acc[channels[0]] = [];
}

      // const inputName = `input-${channels[0]}-${myDict[channels[0]]}`;
       	  acc[channels[0]].push({'source': channels[0], 'url': url, 'comment': ""});
      

    return acc;
  }, {});
});
  
  
  console.log(inputValues);
// PREV WORKING VAR
//  const handleInputChange = (channel, idx, field, value) => {
//    // const { name, value } = event.target;
//    setInputValues((prevValues) => ({ 
//	  ...prevValues, 
//	  [channel]: {...prevValues[channel], [field]: value} 
//	}));
//  };
  
  const handleInputChange = (key, index, field, value) => {
    setInputValues({
      ...inputValues,
      [key]: inputValues[key].map((item, i) => {
        if (i === index) {
          return {...inputValues[key][i], [field]: value};
        }
        return item;
      })
    });
  };

  // The way to reduce is cool, when you need to find some data associated to control by its name.
  // You're good to go if you have just plain list - without any hierarchy.
  
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  // };


  const handleRemoveClick = (key, idx) => {
    const newObject = { ...inputValues };
	// if (newObject[key].length == 1) {
    //     delete newObject[key];
	// } else {
	// 	delete newObject[key][idx];
	// }
	newObject[key] = newObject[key].filter((_, i) => i !== idx);
    setInputValues(newObject);
  };

  // if there is a plan to delegate request handling to parent component
  const handleButtonClick = (inputRef, idx) => {
    const inputValue = inputValues[inputRef][idx];
	console.log(inputValue);
	handleRemoveClick(inputRef, idx);
    onSend(inputRef, inputValue);
  };
  

  const [formData, setFormData] = useState({});

//  const handleInputChange = (channel, value, j) => {
//    setFormData(prevState => ({
//      ...prevState,
//      [channel]: {...prevState[channel], [j]: value}
//    }));
//  };
  
  // if there is a plan to handle requests directly in this component
  // const handleButtonClick = (channel) => {
  //   const data = formData[channel];
  //   console.log(`Sending data for ${channel}:`, data);
  // };


  // onChange={handleInputChange} - is a good shortcut until you have defined name= in the same element
  // and you have plain key-value dictionary, when you have multiple anchors, say name= and field purpose
  // there is a need po pass parameters
  // this becomes possible because of the following:
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;

  return ( 
    <div>
	{inputValues ? (
      <ol className="list-group list-group-numbered" style={{paddingTop: "50px"}}>
        {inputValues && Object.entries(inputValues).map(([channel, urls], i) => {
		  return urls.length > 0 ? (
			<li key={channel} className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto flex-fill">
                <div className="fw-bold">{channel}</div>
	  		    {urls.map((urlq, j) =>
			      <form key={j} className="row g-3 mt-1">
                    <div className="col-sm-8">
                      <div className="input-group">
                        <button className="btn btn-outline-primary input-group-text-btn" type="button">
						  <FontAwesomeIcon icon={faPaperPlane} onClick={()=> window.open(urlq.url, "_blank")} />
						</button>
						
                        <input 
						  type="text" 
						  className="form-control text-truncate" 
						  placeholder="Recipient's username" 
						  aria-label="Recipient's username with two button addons" 
						  onChange={(event) => handleInputChange(channel, j, 'url', event.target.value)}
						  value={inputValues[channel][j]['url']}
						  readOnly />
	                    {/* <button className="btn btn-outline-primary input-group-text-btn" type="button"><FontAwesomeIcon icon={faPen} /></button> */}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="input-group">
                        <input 
						  type="text" 
						  className="form-control text-truncate" 
						  onChange={(event) => handleInputChange(channel, j, 'comment', event.target.value)}
						  placeholder="reason" 
						  value={inputValues[channel][j]['comment']}
						  aria-label="Recipient's username with two button addons"/>
                        <button className="btn btn-outline-secondary input-group-text-btn" type="button" onClick={() => handleButtonClick(channel, j)}>exclude</button>
                      </div>
                    </div>
                  </form>
			    )}
                </div>
                <span className="badge bg-primary rounded-pill">{inputValues[channel].length}</span>
            </li> ) : (
			  <p></p>
			)}		
	    )}
        </ol>
		 ) : null}
    </div>
 );
}



function App() {
  // There was a lot of mistery around how many requests simple React app is sending.
  // When done right still it sends x2 request instead of just 1.
  // https://github.com/axios/axios/issues/2825#issuecomment-784264784
  // This isn't a problem with axios but in your React development environment(!).
  // The component is rendered x2 if you're in StrictMode. 
  // The purpose of this to uncover unpredicted side-effects, it doesn't happen in production
  // ```js
  // root.render(
  //   <React.StrictMode>  --> <>
  //     <App />
  //   </React.StrictMode>  --> </>
  // );

  const handleSend = (inputName, inputValue) => {
    console.log(`Sending <${inputValue}> from <${inputName}>.`);  // do note that formatting var of type Object will lead to [object Object] representation
	// http://172.17.10.57:8089/exclude_url
    axios.post('https://info.folist.org/exclude_url', { 'source': inputValue['source'], 'url': inputValue['url'], 'comment': inputValue['comment'] })           // console.log() shows the internals of an object 
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
    	
	
	
  };

  const [tgNews, setTgNews] = React.useState(null);

  // The /fetchData/ function is defined inside the /useEffect/ hook. It is called once when the component mounts(!).
  useEffect(() => {

    const endpoint1 = "https://info.folist.org/telegram_news";
    const request1 = axios.get(endpoint1);
    // This wrapper is disturbing by preventing requests from sending.
	// const fetchData = async () => {  
	axios.all([request1])
      .then(axios.spread((...responses) => {
	    console.log('response1: ', responses[0].data);
        setTgNews(responses[0].data);
    }))
    .catch(errors => {
      console.log('E:', errors)
    })
    // }
  }, ['']);
  
  // The /fetchData/ function is defined outside the /useEffect/ hook.
  // This means that every time the component renders(!), a new request is made to the server.
  // React.useEffect(() => {fetchData();}, []);
  // By the way, the same thing is concerning axios.get() if the variable is defined
  // outside /useEffect/ it will produce a new request every time the component renders as well.
  // Request related code should live inside the /useEffect/ hook.

  // There is no other way than just send object retrieved from API as a whole.
  // Some stats that are in can be very handy.
  // That's why resData_TgNews['data'] is not an option.

  return (
    <div className="container">
	{tgNews ? (
	  <InputButtonSet tgNews={tgNews} onSend={handleSend} />
	) : (
	  <p>Loading data...</p>
    )}
    </div>
  );
}

export default App;




